import './App.css';

export default function Footer() {
  return (
    <div id="footer">
      <p>
        A DatModo project, in partnership with <a href='https://lucyinstitute.nd.edu' target="_blank">The Lucy Family Institute for Data and Society</a>
      </p>
      <p class='mini'>
        US Capitol Building by Vectors Point from <a href="https://thenounproject.com/browse/icons/term/us-capitol-building/" target="_blank" title="US Capitol Building Icons">Noun Project</a> (CC BY 3.0)
      </p>
    </div>
  )
};
