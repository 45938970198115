import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    field: 'candidate',
    headerName: 'Candidate',
    width: 250,
    editable: false,
  },
  {
    field: 'state_abbrev',
    headerName: 'State',
    width: 100,
    editable: false,
  },
  {
    field: 'cand_party',
    headerName: 'Party',
    width: 100,
    editable: false,
  },
  {
    field: 'incumbent',
    headerName: 'Incumbent',
    width: 130,
    editable: false,
  },	
  {
    field: 'cd',
    headerName: 'District',
    width: 120,
    editable: false,
    number: true,
  },
  {
    field: 'years',
    headerName: 'Years Available',
    width: 240,
    editable: false,
    number: false,
  }
];

export default function CandidateGrid(candidateData) {

    const navigate = useNavigate();
    const handleRowClick = (params) => {
        navigate(`/candidate/${params.row.candidate_slug}`);
    };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={candidateData.candidates}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[5, 15, 30]}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
      />
    </Box>
  );
}
