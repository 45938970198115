import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Box,
    Tab,
    Tabs }
from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { apiUrl } from './utils/api';

export default function CandidateDetail(candidate_slug) {
    const [candidateDetail, setCandidateDetail] = useState([]);
    const slug = candidate_slug.candidate;
    const [year, setYear] = useState(0);

    function CustomTabPanel(props) {
        const { children, value, year, ...other } = props;
        return (
          <div
            key={year+"-year-tabpanel"}
            role="tabpanel"
            hidden={value !== year}
            id={`simple-tabpanel-${year}`}
            aria-labelledby={`simple-tab-${year}`}
            {...other}
          >
            {value === year && <Box sx={{ p: 3 }}>{children}</Box>}
          </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    useEffect(() => {
        fetch(`${apiUrl}/candidates/${slug}`)
            .then((response) => response.json())
            .then((data) => setCandidateDetail(data));
    }, [slug]);

    useEffect(() => {
        if (candidateDetail.years) {
            setYear(Math.max.apply(null, candidateDetail.years));
        }
        console.log(candidateDetail.issues);
    }, [candidateDetail]);

    const handleChange = (event, newValue) => {
        setYear(newValue);
    };

    function tabProps(year) {
        return {
          id: `simple-tab-${year}`,
          'aria-controls': `simple-tabpanel-${year}`,
          value: year
        };
      }

    function filterByYear(issues, year) {
        var filtered = Object.fromEntries(Object.entries(issues).filter(([k,v]) => v.year === year));
        return filtered;
    }

    return (
        <div>
            {(candidateDetail?.length === 0 || year === 0) ? (
                <CircularProgress />
            ) : (
                <div className="candidate-detail" id="candidate-detail">
                <Link class='back-button' to={'/explore'}>&#8249; Back</Link>

                <h2>{candidateDetail.candidate} - {candidateDetail.state} {candidateDetail.cd}</h2>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={year} onChange={handleChange} aria-label="basic tabs example">
                    {candidateDetail.years.map((year) => (
                        <Tab key={year+"-year-tab"} label={year} {...tabProps(year)} />
                    ))}
                </Tabs>
                </Box>
                <CustomTabPanel value={year} year={year} index={year}>
                    {Object.entries(filterByYear(candidateDetail.issues, year)).map(( [hdr, entry] ) => (
                        <div className="issue-entry" key={hdr}>
                          <div>
                            <h2>{hdr}</h2>
                            <div class='mip-container'>MIP Codes:
                              {candidateDetail.issues[hdr].mip_codes.map((mip) => (
                                  <div class='mip-pill'> {mip.substring(4)}</div>
                              ))}
                            </div>
			    <div class='mip-container'>CAP Codes:
                              {candidateDetail.issues[hdr].cap_codes.map((mip) => (
                                  <div class='cap-pill'> {mip.substring(4)}</div>
                              ))}
                            </div>
                          </div>
                          <hr />
                          <p class='issue-content'>{entry.text}</p>
                        </div>
                    ))}
                </CustomTabPanel>
                </div>
            )}
        </div>
    );
};
