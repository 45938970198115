import './App.css';
import Header from './Header';
import Footer from './Footer';
import Data from './Data';

import { useEffect, useState } from 'react';
import { CircularProgress,
         Box }
         from '@mui/material';
import { useParams } from 'react-router-dom';
import { apiUrl } from './utils/api'

function App() {

  const [candidates, setCandidates] = useState([]);
  const [zip_codes, setZipCodes] = useState([]);

  const params = useParams();
  const { candidate_slug } = params;

  // fetch candidate list from api
  useEffect(() => {
    fetch(`${apiUrl}/candidates`)
      .then((response) => response.json())
      .then((data) => setCandidates(data));
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/zips`)
      .then((response) => response.json())
      .then((data) => setZipCodes(data));
  }, []);

  return (
    <div id="page-wrapper">
      <Header />
      <div id='data' class='content'>
        {candidates?.length === 0 ? (
         <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30vh'}}>
          <CircularProgress />
        </Box>
        ) : (
         <Data candidates={candidates}
               slug={candidate_slug}
               zip_codes={zip_codes} />
        )}
      </div>
      <Footer/>
    </div>
  );
}

export default App;
