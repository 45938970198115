
import React from "react";
import Header from "./Header";
import Footer from "./Footer"

function About(props) {
    const persons = [
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      },
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      },
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      }
    ]
    return (
      <div id="page-wrapper">
        <Header />
        <div id="about" class='content'>
          <h2>Welcome to CampaignView</h2>
          <div class="grid-card">
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.</p>

            <p>Sociosqu ridiculus sodales nulla dui ullamcorper blandit diam. Ac donec finibus laoreet consequat amet eget. Dis eros viverra, dapibus velit rutrum diam porttitor malesuada. Lacus placerat id neque pulvinar ac odio quam justo. Sollicitudin fermentum natoque tempus sociosqu eu tortor commodo convallis. Tristique proin dui facilisi ridiculus habitasse eget venenatis. Neque curae integer aenean erat ultricies metus. Per ullamcorper montes curabitur feugiat parturient faucibus. Risus quam platea penatibus congue lacus eu mi nisi. Tortor adipiscing condimentum cras orci sem est.</p>

            <p>Semper imperdiet id at duis natoque etiam. Sagittis efficitur curae mus curabitur eleifend amet. Habitasse facilisi fames primis porttitor dolor; efficitur dolor amet. Torquent lectus pretium eu aliquam a. Justo himenaeos vivamus himenaeos lectus praesent primis suspendisse. Phasellus vestibulum primis donec magna feugiat himenaeos proin at. Feugiat posuere feugiat eget eu; etiam augue. Elementum ex vestibulum lacus velit porta praesent tempus pharetra.</p>
          </div>
          <h2>The CampaignView team</h2>
          <div class='grid-card'>
            {persons.map(person =>
              <div class='grid-card person'>
                <img src={person.img} width='200rem'/>
                <div class='details'>
                  <h3>{person.name}</h3>
                  <p>{person.bio}</p>
                </div>
              </div>
            )}
          </div>
          <h2>Citing CampaignView</h2>
          <div class='grid-card'>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default About;