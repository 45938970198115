
import React from "react";
import Header from "./Header";
import Footer from "./Footer"

function About(props) {
    return (
      <div id="page-wrapper">
        <Header />
        <div id="about" class='content'>
          <div class="grid-card">
            <h2>About</h2>
            <p>This project collects data from the campaign websites of candidates across the USA.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default About;